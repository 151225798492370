import store from '@/store'
const title = store.getters.title
export default {
  targetArr: [{
      title: `${title}统计数据`,
      list: [{
          label: 'DAU', // label
          width: 120, // 宽度
          prop: 'dau', // 属性
          align: 'left', // 对齐方式
          desc: '每天打开应用的独立用户数',
          sortable: false
        },
        {
          label: 'ARPDAU',
          width: 120,
          prop: 'arpu',
          align: 'left',
          desc: 'ARPDAU = 收益 / DAU',
          sortable: false
        },
        {
          label: '渗透率',
          width: 120,
          prop: 'permeability',
          align: 'left',
          desc: '每天观看过广告的用户占比，渗透率=DEU/DAU',
          sortable: false
        },
        {
          label: '展示/DAU',
          width: 120,
          prop: 'aipau',
          align: 'left',
          desc: '人均展示次数',
          sortable: false
        },
        {
          label: 'DEU',
          width: 120,
          prop: 'deu',
          align: 'left',
          desc: '每天观看过广告的独立用户数',
          sortable: false
        },
        {
          label: 'ARPDEU',
          width: 120,
          prop: 'arpDeu',
          align: 'left',
          desc: 'ARPDEU = 收益 / DEU',
          sortable: false
        },
        {
          label: '展示/DEU',
          width: 120,
          prop: 'aipu',
          align: 'left',
          desc: '观看过广告的用户人均展示次数',
          sortable: false
        },
        {
          label: '广告成交额',
          width: 150,
          prop: 'advPrice',
          align: 'left',
          desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
          sortable: false
        },
        {
          label: '利润率',
          width: 120,
          prop: 'profitRate',
          align: 'left',
          desc: `(成交额-预估收益)/成交额*100%`,
          sortable: false
        },
        {
          label: '新用户',
          width: 120,
          prop: 'newLogin',
          align: 'left',
          desc: '首次使用应用的用户数',
          sortable: false
        },
        {
          label: '新用户占比',
          width: 120,
          prop: 'newUserRate',
          align: 'left',
          desc: '首次使用应用的用户占比，计算公式：新增用户/DAU',
          sortable: false
        },
        {
          label: '流量请求',
          width: 140,
          prop: 'request',
          align: 'left',
          desc: `应用向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
          sortable: true
        },
        {
          label: '流量填充率',
          width: 120,
          prop: 'requestFilledRate',
          align: 'left',
          desc: `应用向${title}发送请求后，响应成功的占比`,
          sortable: false
        },
        {
          label: '流量填充',
          width: 140,
          prop: 'response',
          align: 'left',
          desc: `汇总行表示流量填充，即应用向${title}发送请求后有广告返回的次数；其余行表示广告填充，即${title}向广告平台发送请求后返回成功次数`,
          sortable: false
        },
        {
          label: '展示',
          width: 150,
          prop: 'impress',
          align: 'left',
          desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
          sortable: true
        },
        {
          label: '展示率',
          width: 120,
          prop: 'impressRate',
          align: 'left',
          desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`,
          sortable: false
        },
        {
          label: '展请率',
          width: 140,
          prop: 'impressRequestRate',
          align: 'left',
          desc: `${title}统计到的展示占${title}收到的请求比例；`,
          sortable: false
        },

        {
          label: '点击',
          width: 120,
          prop: 'click',
          align: 'left',
          desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`,
          sortable: true
        },
        {
          label: '点击（去重）',
          width: 120,
          prop: 'uniqClick',
          align: 'left',
          sortable: false,
          desc: `${title}统计的广告点击数反映了用户的实际点击行为，其中包括可能对同一广告的多次点击。去重是对这些数据进行过滤，同一广告被重复点击，只记录一次。`
        },
        {
          label: '点击率',
          width: 120,
          prop: 'clickRate',
          align: 'left',
          desc: `${title}统计的点击率，点击率=点击数/展示数`,
          sortable: false
        },
        {
          label: '点击率（去重）',
          width: 140,
          prop: 'uniqClickRate',
          align: 'left',
          sortable: false,
          desc: `${title}统计的点击率(去重)，点击率（去重）=点击(去重)/展示数`
        },
        {
          label: '预估收益',
          width: 150,
          prop: 'income',
          align: 'left',
          desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
          sortable: true
        },
        {
          label: '预估eCPM',
          width: 150,
          prop: 'estimatedRevenueEcpm',
          align: 'left',
          desc: `（预估收益/${title}统计的展示）*1000`,
          sortable: false
        },
        {
          label: '广告请求',
          width: 120,
          prop: 'advRequest',
          align: 'left',
          desc: `汇总行表示流量请求，即应用向${title}发起请求的次数；其他行表示广告请求，即${title}向广告平台发起请求的次数`,
          sortable: true
        },
        {
          label: '广告填充率',
          width: 120,
          prop: 'advRequestFilledRate',
          align: 'left',
          desc: `广告填充率，即${title}向广告平台发送请求后返回成功的占比`,
          sortable: false
        },
        {
          label: '竞价',
          width: 120,
          prop: 'askPriceRequest',
          align: 'left',
          desc: `${title}服务端向竞价广告平台发起的竞价次数；`,
          sortable: false
        },
        {
          label: '竞价响应率',
          width: 150,
          prop: 'askPriceFilledRate',
          align: 'left',
          desc: `竞价广告平台竞价响应率 = 竞价响应次数 / 竞价次数 * 100%`,
          sortable: false
        },
        {
          label: '竞价响应eCPM',
          width: 180,
          prop: 'askPrice',
          align: 'left',
          desc: `竞价广告平台在竞价响应时返回的平均eCPM`,
          sortable: false
        },
        {
          label: '竞价成功率',
          width: 140,
          prop: 'bidSuccessRate',
          align: 'left',
          desc: `-`,
          sortable: false
        },
        {
          label: '展示占比',
          width: 140,
          prop: 'display',
          align: 'left',
          desc: `${title}统计的各维度下的广告展示占比；`,
          sortable: false
        },
        {
          label: '预估收益占比',
          width: 120,
          prop: 'revenuePercentage',
          align: 'left',
          desc: `${title}统计的各维度下的预估收益占比；`,
          sortable: false
        },
        {
          label: 'CPC',
          width: 100,
          prop: 'cpc',
          align: 'left',
          desc: `预估收益/点击`,
          sortable: false
        }
      ]
    },
    {
      title: 'API数据',
      list: [{
          label: '收益API',
          width: 120,
          prop: 'unitRevenue',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的收益`,
          sortable: true
        },
        {
          label: 'eCPM API',
          width: 120,
          prop: 'unitEcpm',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的收益和展示API计算出eCPM API，公式：（收益/展示API）*1000`,
          sortable: false
        },
        {
          label: '展示API',
          width: 120,
          prop: 'unitImpression',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的展示`,
          sortable: true
        },
        {
          label: '点击API',
          width: 120,
          prop: 'unitClick',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的点击`,
          sortable: true
        },
        {
          label: '点击率API',
          width: 120,
          prop: 'unitCtr',
          align: 'left',
          desc: '点击API/展示API',
          sortable: false
        },
        {
          label: '请求API',
          width: 120,
          prop: 'unitRequest',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的广告请求数，部分平台不提供此数据`,
          sortable: false
        },
        {
          label: '填充API',
          width: 120,
          prop: 'unitFilledRate',
          align: 'left',
          desc: `${title}通过Reporting API向广告平台拉取到的填充率，部分平台不提供此数据`,
          sortable: false
        }
      ]
    },
    {
      title: '数据GAP',
      list: [{
          label: '展示Gap',
          width: 120,
          prop: 'impressionGap',
          align: 'left',
          desc: `${title}统计展示与广告平台统计展示的差异，公式为 展示Gap=(展示-展示API)/展示API`,
          sortable: false
        },
        {
          label: '点击Gap',
          width: 120,
          prop: 'clickGap',
          align: 'left',
          desc: `${title}统计点击与广告平台统计点击的差异，公式为 点击Gap=(点击-点击API)/点击API`,
          sortable: false
        }
      ]
    }
  ],
  sureArr: [{
      label: 'DAU', // label
      width: 120, // 宽度
      prop: 'dau', // 属性
      align: 'left', // 对齐方式
      desc: '每天打开应用的独立用户数',
      sortable: false
    },
    {
      label: 'ARPDAU',
      width: 120,
      prop: 'arpu',
      align: 'left',
      desc: 'ARPDAU = 收益 / DAU',
      sortable: false
    },
    {
      label: '渗透率',
      width: 120,
      prop: 'permeability',
      align: 'left',
      desc: '每天观看过广告的用户占比，渗透率=DEU/DAU',
      sortable: false
    },
    {
      label: '展示/DAU',
      width: 120,
      prop: 'aipau',
      align: 'left',
      desc: '人均展示次数',
      sortable: false
    }, {
      label: '流量请求',
      width: 140,
      prop: 'request',
      align: 'left',
      desc: `应用向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
      sortable: true
    }, {
      label: '展示',
      width: 150,
      prop: 'impress',
      align: 'left',
      desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
      sortable: true
    }, {
      label: '预估收益',
      width: 150,
      prop: 'income',
      align: 'left',
      desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
      sortable: true
    },
    {
      label: '预估eCPM',
      width: 150,
      prop: 'estimatedRevenueEcpm',
      align: 'left',
      desc: `（预估收益/${title}统计的展示）*1000`,
      sortable: false
    },
    {
      label: '收益',
      width: 120,
      prop: 'unitRevenue',
      align: 'left',
      desc: `${title}通过Reporting API向广告平台拉取到的收益`,
      sortable: true
    },
    {
      label: 'eCPM API',
      width: 120,
      prop: 'unitEcpm',
      align: 'left',
      desc: `${title}通过Reporting API向广告平台拉取到的收益和展示API计算出eCPM API，公式：（收益/展示API）*1000`,
      sortable: false
    }
  ]
}
