<template>
  <div class="hour-chart-container">
    <div class="hour-chart-left">
      <a-spin class="spin" :spinning="isLoading"><div class="chart" ref="chart"></div></a-spin>
    </div>
    <div class="hour-chart-right">
      <div class="title">指标</div>
      <a-menu :selectedKeys="currentChecked" type="inner" class="menu_checked" @select="handleChecked">
        <a-menu-item class="menu_item" v-for="item in checkList" :key="item.value">
          <div :title="item.label">
            <span>{{ item.label }}</span>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { getHourChart } from '@/api/reportdatas'
import { numFormat } from '@/utils/dealNumber'
import options from './options'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      currentChecked: ['impress'],
      options,
      checkList: [
        {
          label: '展示',
          value: 'impress'
        },
        {
          label: '预估收益',
          value: 'income'
        },
        {
          label: '预估eCPM',
          value: 'estimatedRevenueEcpm'
        },
        {
          label: '流量请求',
          value: 'request'
        },
        {
          label: '流量填充率',
          value: 'requestFilledRate'
        },
        {
          label: '点击',
          value: 'click'
        },
        {
          label: '点击率',
          value: 'clickRate'
        },
        {
          label: '广告请求',
          value: 'advRequest'
        },
        {
          label: '广告填充率',
          value: 'advRequestFilledRate'
        },
        {
          label: '展示率',
          value: 'impressRate'
        },
        {
          label: '展请率',
          value: 'impressRequestRate'
        },
        {
          label: '竞价数',
          value: 'askPriceRequest'
        },
        {
          label: '竞价填充率',
          value: 'askPriceFilledRate'
        },
        {
          label: '竞价响应eCPM',
          value: 'askPrice'
        },
        {
          label: '广告填充',
          value: 'advResponse'
        }
      ],
      isLoading: false,
      firstEcjartsList: [],
      secondEcjartsList: [],
      dateArr: [],
      dataList: [],
      dateList: [],
      colorList: [
        {
          c1: 'rgba(183, 105, 247, 1)',
          c2: 'rgba(138, 89, 251, 1)'
        },
        {
          c1: 'rgba(95, 233, 196, 1)',
          c2: 'rgba(62, 219, 223, 1)'
        },
        {
          c1: 'rgba(249, 169, 110, 1)',
          c2: 'rgba(248, 123, 104, 1)'
        },
        {
          c1: 'rgba(250, 84, 124, 1)',
          c2: 'rgba(247, 65, 108, 1)'
        },
        {
          c1: 'rgba(22, 178, 246, 1)',
          c2: 'rgba(31, 125, 238, 1)'
        },
        {
          c1: 'rgba(248, 202, 116, 1)',
          c2: 'rgba(252, 159, 74, 1)'
        }
      ]
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    query: {
      handler (val) {
        this.getHoursEcharts()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    handleChecked ({ key }) {
      this.currentChecked = [key]
      this.changeEcharts()
    },
    getHoursEcharts () {
      this.isLoading = true
      getHourChart(this.query).then((resp) => {
        // x轴 24小时
        const arr = []
        for (let i = 0; i < 24; i++) {
          arr.push(i)
        }
        this.options.xAxis.data = arr
        this.dateArr = this.query.dateList
        if (resp.code === 200) {
          // Js对象obj按照键名key排序
          const newObj = {}
          Object.keys(resp.data)
            .sort()
            .map((key) => {
              newObj[key] = resp.data[key]
            })
          this.dataList = Object.values(newObj)
          this.dateList = Object.keys(newObj)
        }
        this.isLoading = false
        this.changeEcharts()
      })
    },
    // 点击维度
    changeEcharts () {
      this.options.series = []
      this.options.legend.data = []
      let index
      this.dataList.length > 0 &&
        this.dataList[0].forEach((child, c) => {
          if (child.field === this.currentChecked[0]) {
            index = c
          }
        })
      this.dateArr.forEach((item, i) => {
        // 若未返回数据则不做以下处理
        if (!this.dataList.length || this.dataList.length <= i) return
        // this.options.legend.data.push(item + '-' + this.dataList[i][index].name)
        this.options.legend.data.push(this.dateList[i] + '-' + this.dataList[i][index].name)
        this.options.series.push({
          // name: this.dateArr[i] + '-' + this.dataList[i][index].name,
          name: this.dateList[i] + '-' + this.dataList[i][index].name,
          type: 'line',
          smooth: true,
          data: this.dataList[i][index].data.map((data) => data || 0),
          symbol: 'circle', // 设定为实心点
          symbolSize: 4, // 设定实心点的大小
          itemStyle: {
            normal: {
              color: this.colorList[i].c1,
              lineStyle: {
                color: this.colorList[i].c1,
                width: 1.8
              }
            }
          },
          areaStyle: {
            color: this.query.dateList.length <= 2 ? {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.colorList[i].c2 // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            } : 'rgba(255, 255, 255, 0)'
          }
        })
      })
      // formatter方法
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (item.seriesName.includes('预估收益') || item.seriesName.includes('预估eCPM')) {
            temp.value = '￥' + numFormat(item.value, 3, '')
          } else if (
            item.seriesName.includes('流量填充率') ||
            item.seriesName.includes('广告填充率') ||
            item.seriesName.includes('点击率') ||
            item.seriesName.includes('请求填充率') ||
            item.seriesName.includes('展示率') ||
            item.seriesName.includes('竞价填充率') ||
            item.seriesName.includes('展请率')
          ) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
        })

        return params[0].axisValue + '时<br>' + str
      }
      this.echartsInit()
    },
    echartsInit () {
      this.myEcharts && this.myEcharts.dispose()
      if (!this.$refs.chart) return
      this.myEcharts = echarts.init(this.$refs.chart, null)
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.hour-chart-container {
  border-radius: 5px;
  position: relative;
  display: flex;
  background: #fff;
  margin-top: 10px;
  padding: 20px;
  height: 400px;
  .hour-chart-right {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-width: 180px;
    width: 20%;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-menu-vertical {
      border-right: none;
    }
    .title {
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      background: #eee;
    }
    .menu_checked {
      overflow-y: auto;
      flex-grow: 1;
      .menu_item {
      background: fade(@primary-color, 2%);
      height: 29px;
      line-height: 29px;
      font-size: 13px;
      margin: 3px;
      border-left: 3px solid #fff;
      &.ant-menu-item-selected {
        border-left: 3px solid;
        background: fade(@primary-color, 10%);
      }
    }
    }
  }
  .hour-chart-left {
    flex-grow: 1;
    position: relative;
    .spin {
      display: inline-block;
      width: 100%;
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
